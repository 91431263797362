<template>
  <div>
    <v-layout pt-12></v-layout>
    <v-layout pt-12></v-layout>
    <v-layout wrap pt-12 justify-center>
      <v-flex xs12 md2 align="center">
        <v-img src="../../assets/icons/tick.png" aspect-ratio="1" contain height="50px"></v-img>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 md12 text-center>
        <span style="font-size:18px;color:#000;font-weight:400">Login Successfull</span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 md3 align="center">
        <v-img src="../../assets/person.png" contain height="300px"></v-img>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["loginData"],
  data() {
    return {};
  },
  beforeMount() {
    this.submit();
  },
  methods: {
    submit() {
      setTimeout(() => {
        this.$router.push("/Dashboard");
      }, 1000);
    }
  }
};
</script>