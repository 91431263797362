<template>
  <div style="font-family: montserrat_Regular;">
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <v-layout pb-12 xs12 sm12 md12 wrap>
      <v-flex>
        <v-layout wrap pt-10 justify-center>
          <v-flex xs12 md8 text-center>
            <span class="login_head">Log in</span>
          </v-flex>
        </v-layout>
        <v-form @submit.prevent="submit">
          <v-layout wrap justify-center pt-3>
            <v-flex xs12 sm6 md3 pt-5 px-2>
              <v-text-field
                ref="userName"
                :rules="[rules.required]"
                v-model="userName"
                :error-messages="msg_username"
                label="Username"
                color="#26af82"
                required
              ></v-text-field>
              <v-text-field
                ref="password"
                v-model="password"
                label="Password"
                :rules="[rules.required]"
                :error-messages="msg_password"
                type="password"
                color="#26af82"
                required
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-layout v-if="msg" wrap justify-center>
            <v-flex xs12 md3 pa-2 text-center>
              <span style="font-size:14px;color:red;">{{msg}}</span>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center>
            <v-flex xs12 md12 pt-2 text-center>
              <v-btn
                rounded
                :disabled="false"
                width="250px"
                class="text-capitalize"
                dark
                small
                :ripple="false"
                color="#26af82"
                type="submit"
              >Log In</v-btn>
            </v-flex>
          </v-layout>
        </v-form>

        <v-layout wrap justify-center>
          <v-flex xs12 md3 pt-5 text-center>
            <router-link to="/ForgotPassword">
              <span style="font-size:14px;color:#26af82">Forgot Password?</span>
            </router-link>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs12 sm6 md3 pt-5 text-center>
            <span>
              <hr style="height:1px; border:none;background-color:#e6e6e6;" />
            </span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import store from "../../store";
import VueElementLoading from "vue-element-loading";

export default {
  components: {
    VueElementLoading
  },
  data() {
    return {
      userName: null,
      password: null,
      loginexist: false, // Severe Problem set false
      rememberMe: false,
      loginBtn: true,
      appLoading: false,
      msg: null,
      msg_username: null,
      msg_password: null,
      rules: {
        required: value => !!value || "Required"
      },
      otp: null
    };
  },
  computed: {
    form() {
      return {
        userName: this.userName,
        password: this.password
      };
    }
  },
  watch: {
    userName: function() {
      if (this.userName && this.password) this.loginBtn = false;
      else this.loginBtn = true;
    },
    password: function() {
      if (this.userName && this.password) this.loginBtn = false;
      else this.loginBtn = true;
    },
    otp: function() {
      if (this.otp.length == 5) {
        document.getElementById("otpinpt").classList.add("otphide");
      } else {
        document.getElementById("otpinpt").classList.remove("otphide");
      }
    }
  },
  methods: {
    submit() {
      this.formHasErrors = false;

      Object.keys(this.form).forEach(f => {
        if (!this.form[f]) this.formHasErrors = true;

        this.$refs[f].validate(true);
      });

      this.appLogin();
    },
    appLogin() {
      if (this.formHasErrors) {
        // this.msg = "Please Validate Input";
      } else {
        this.appLoading = true;
        this.OTPVerified = true;
        var logindata = {};
        logindata["username"] = this.userName;
        logindata["password"] = this.password;
        logindata["override"] = this.loginexist;
        axios({
          method: "POST",
          url: "/user/login",
          data: logindata
        })
          .then(response => {
            this.appLoading = false;
            // this.msg = response.data.msg;
            if (response.data.status) {
              localStorage.setItem("token", response.data.token);
              store.commit("loginUser", true);
              store.commit("company", true);
              this.$emit("stepper", {
                winStep: 2,
                progress: 100
              });
              // this.$emit("stepper", {
              //   winStep: 1,
              //   userId: response.data.id,
              //   authcode: response.data.code
              // });
            } else {
              if (
                response.data.msg ===
                "You are already logged in. Re-submit to destroy existing session and login"
              ) {
                this.loginexist = true;
                this.msg = response.data.msg;
                this.msg_username = null;
                this.msg_password = null;
              } else if (response.data.msg == "user not found.") {
                this.msg_username = response.data.msg;
                this.msg_password = null;
              } else {
                this.msg_password = response.data.msg;
                this.msg_username = null;
              }
            }
          })
          .catch(err => {
            this.msg = err;
            this.msg = "Server Unreachable";
            this.appLoading = false;
          });
      }
      // setTimeout(() => {
      //   this.$emit("stepper", { winStep: 1 });
      // }, 1000);
    }
  }
};
</script>
<style>
.login_head {
  font-family: opensans;
  font-size: 20px;
  font-weight: 400;
}
</style>
<style lang="scss" scoped>
// .otpinpt {
//   font-size: 14px;
//   letter-spacing: 40px;
//   width: 260px;
//   padding-left: 10px;
//   // border-bottom: 2px solid #26af82 !important;
//   background-image: linear-gradient(to left, #26af82 80%, #fff 0%);
//   background-position: bottom;
//   background-size: 50px 2px;
//   background-repeat: repeat-x;
//   background-position-x: 35px;
//   &:focus {
//     outline: none;
//   }
// }
// .otphide {
//   color: transparent;
//   text-shadow: 0 0 0 #000;
// }
</style>