<template>
  <div>
    <v-snackbar v-model="showotpsnack" color="#00a972" right :timeout="timeout">
      One-time PIN Sent Successfully
      <v-btn text @click="showotpsnack = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <v-layout wrap pt-10>
      <v-flex xs12 md2 pl-10 justify-center>
        <v-img @click="pageBack" src="../../assets/icons/back.png" height="50px" width="50px"></v-img>
      </v-flex>
      <v-flex xs12 md8 text-center align-self-end>
        <span class="login_head">Two Factor Verification</span>
      </v-flex>
      <v-flex xs12 md12 pt-1 text-center>
        <span
          style="font-size:12px;font-weight:400;color:#a9a9a9;"
        >We have sent you a One-time PIN to your email ID</span>
      </v-flex>
      <v-flex xs12 md2></v-flex>
    </v-layout>
    <v-layout wrap justify-center pt-12>
      <v-flex xs12 md12 text-center>
        <span style="font-size:14px;font-weight:300;color:#a9a9a9;">Security Code</span>
      </v-flex>
    </v-layout>
    <v-layout justify-center>
      <v-flex xs12 md3 text-center pl-4>
        <vie-otp-input
          inputClasses="otp-input"
          :numInputs="5"
          separator
          :shouldAutoFocus="true"
          @on-complete="validateOTP"
        />
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 md3 pl-6>
        <span
          v-if="!otpexpired"
          style="font-size:14px;font-weight:300;color:#a9a9a9;"
        >Your one-time PIN will expire in {{ timeLeft }}</span>
        <span v-else style="font-size:14px;font-weight:300;color:red;">Your one-time PIN has expired</span>
      </v-flex>
    </v-layout>
    <v-layout v-if="showotpbtn" wrap justify-center>
      <v-flex xs12 md3 pl-6>
        <span
          @click="resentOTP"
          onMouseOver="this.style.cursor='pointer'"
          style="font-size:12px;font-weight:500;color:#a9a9a9;"
        >Resend Code</span>
      </v-flex>
    </v-layout>
    <v-layout justify-center pt-12 pl-5>
      <v-flex v-if="OTPVerified" xs12 md2 align="center">
        <v-img src="../../assets/icons/verification.png" aspect-ratio="1" contain height="50px"></v-img>
      </v-flex>
    </v-layout>
    <v-layout justify-center pt-3>
      <v-flex v-if="OTPVerified" xs12 md12 text-center>
        <span style="font-size:16px;font-weight:400;color:#26af82;">OTP Verification Successful</span>
      </v-flex>
    </v-layout>
    <v-layout justify-center pt-3>
      <v-flex xs12 md12 text-center>
        <span style="font-size:16px;font-weight:400;color:red;">{{msg}}</span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pb-12>
      <v-flex xs12 md12 pa-10 text-center>
        <br />
        <v-btn
          rounded
          width="200px"
          class="text-capitalize"
          :ripple="false"
          color="#26af82"
          dark
          @click="submit"
        >Submit</v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import VueElementLoading from "vue-element-loading";
import VieOtpInput from "@bachdgvn/vue-otp-input";
import axios from "axios";
import store from "../../store";
export default {
  components: {
    VieOtpInput,
    VueElementLoading
  },
  props: ["loginData"],
  data() {
    return {
      // OTP: "qscefb",
      OTP: null,
      OTPVerified: false,
      appLoading: false,
      authcode: null,
      userId: null,
      msg: null,
      showotpbtn: false,
      timeLeft: "00:00",
      intervalTimer: null,
      showotpsnack: false,
      timeout: 5000,
      otpexpired: false
    };
  },
  beforeMount() {
    this.setTime(420);
  },
  mounted() {
    this.authcode = this.loginData.authcode;
    this.userId = this.loginData.userId;
    // this.msg = this.loginData.msg;
  },
  methods: {
    pageBack() {
      this.$emit("stepper", {
        winStep: 0,
        otpexpired: false
      });
    },
    validateOTP(value) {
      this.OTP = value;
    },
    resentOTP() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/sendotp",
        params: {
          id: this.userId
        }
      })
        .then(response => {
          this.appLoading = false;
          this.showotpbtn = false;
          this.msg = response.data.msg;
          this.msg = null;
          this.showotpsnack = true;
          this.otpexpired = false;
          this.setTime(420);
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err;
          this.msg = "Please check your internet connection";
        });
    },
    submit() {
      var verdata = {};
      verdata["otp"] = this.OTP;
      verdata["code"] = this.authcode;
      verdata["id"] = this.userId;
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/verifyotp",
        data: verdata
      })
        .then(response => {
          this.appLoading = false;
          this.msg = response.data.msg;
          if (response.data.status) {
            this.OTPVerified = true;
            localStorage.setItem("token", response.data.token);
            store.commit("loginUser", true);
            if (response.data.type == "Individual") {
              store.commit("individual", true);
            }
            if (response.data.type == "Company") {
              store.commit("company", true);
            }
            this.$emit("stepper", {
              winStep: 2,
              mailId: response.data.data.email,
              userId: response.data.id,
              progress: response.data.progress
            });
          } else {
            this.OTP = null;
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err;
          this.msg = "Please check your internet connection";
        });
    },
    setTime(seconds) {
      clearInterval(this.intervalTimer);
      this.timer(seconds);
    },
    timer(seconds) {
      const now = Date.now();
      const end = now + seconds * 1000;
      this.displayTimeLeft(seconds);
      this.countdown(end);
    },
    countdown(end) {
      this.intervalTimer = setInterval(() => {
        const secondsLeft = Math.round((end - Date.now()) / 1000);
        if (secondsLeft == 360) this.showotpbtn = true;
        if (secondsLeft == 0) this.otpexpired = true;
        if (secondsLeft < 0) {
          clearInterval(this.intervalTimer);
          return;
        }
        this.displayTimeLeft(secondsLeft);
      }, 1000);
    },
    displayTimeLeft(secondsLeft) {
      const minutes = Math.floor((secondsLeft % 3600) / 60);
      const seconds = secondsLeft % 60;
      var seconds1 = seconds;
      if (seconds < 10) seconds1 = "0" + seconds;
      this.timeLeft = "0" + minutes + " : " + seconds1;
    }
  }
};
</script>
<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border: 1px solid #26af82;
  outline: 0;
  border-width: 0 0 1px;
  text-align: "center";
  padding-left: 12px;
}

.error {
  border: 1px solid red !important;
}
</style>