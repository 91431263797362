import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import 'es6-promise/auto'
import axios from 'axios'
import VueYoutube from 'vue-youtube'
import VueTelInput from 'vue-tel-input'
import JsonCSV from 'vue-json-csv'
import CKEditor from '@ckeditor/ckeditor5-vue';

import VueElementLoading from 'vue-element-loading'
Vue.component('VueElementLoading', VueElementLoading)


Vue.use(CKEditor);
Vue.use(VueYoutube)
Vue.use(VueTelInput)
Vue.component('downloadCsv', JsonCSV)
Vue.config.productionTip = false

window.axios = require('axios')

var VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

Vue.prototype.baseURL = 'http://43.205.144.230'; // URL for File retriving Globaly initialized

axios.defaults.baseURL = 'http://43.205.144.230'; // Production URL
// axios.defaults.baseURL = 'http://localhost:3000';
axios.defaults.timeout = 60000;

new Vue({
  vuetify,
  router,
  components: { App },
  template: '<App/>',
  render: h => h(App)
}).$mount('#app')
