<template>
  <v-app id="inspire">
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app>
      <v-list dense>
        <template v-for="(item,i) in items">
          <v-list-group
            v-if="item.children"
            :key="i"
            v-model="item.model"
            append-icon
            color="#00a972"
          >
            <template v-slot:activator>
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              link
              router-link
              :to="child.route"
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ child.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item color="#00a972" v-else :key="i" link router-link :to="item.route">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item color="#00a972" @click="appLogout">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="#00a972"
      height="50px"
      dark
      elevation="1"
    >
      <v-app-bar-nav-icon @click.stop="drawer=!drawer" />
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <span>Ecosystem Investor Platform</span>
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items v-if="!appLogin" class="nav_item hidden-sm-and-down">
        <!-- <v-row justify="center"> -->
        <router-link to="/Login">
          <v-btn text :ripple="false" class="nav_item AppMenu">LOG IN</v-btn>
        </router-link>
        <v-spacer></v-spacer>
      </v-toolbar-items>
      <v-toolbar-items v-else class="nav_item hidden-sm-and-down">
        <v-row justify="center">
          <v-menu
            :close-on-content-click="false"
            :nudge-bottom="40"
            :nudge-right="50"
            transition="slide-y-transition"
            style="padding-top:40px;"
            bottom
            dark
          >
            <template v-slot:activator="{ on }">
              <v-btn
                text
                :ripple="false"
                class="nav_item AppMenu"
                style="background-color: #38B98A!important;"
                v-on="on"
              >{{userName}}</v-btn>
            </template>
            <v-card max-width="350">
              <v-layout pa-3>
                <v-flex>
                  <router-link to="/Profile">
                    <v-layout style="cursor:pointer;">
                      <v-flex>
                        <span style="font-size:12px;color:white">Account Settings</span>
                      </v-flex>
                      <v-flex pl-3>
                        <v-icon style="font-size:14px;">mdi-settings</v-icon>
                      </v-flex>
                    </v-layout>
                  </router-link>
                  <v-divider></v-divider>
                  <v-layout @click="appLogout" style="cursor:pointer;">
                    <v-flex text-left>
                      <span style="font-size:12px;">Sign Out</span>
                    </v-flex>
                    <v-flex text-right>
                      <v-icon style="font-size:14px;">mdi-logout</v-icon>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-menu>
          <v-spacer></v-spacer>
        </v-row>
      </v-toolbar-items>
    </v-app-bar>
    <!-- <v-toolbar height="5px" elevation="0"></v-toolbar> -->
    <v-content>
      <v-container class="fill-height" style="overflow-x:scroll;padding:0!important;">
        <v-row align="center" justify="center">
          <router-view style="min-height: 80vh;"></router-view>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import axios from "axios";
import store from "../../store";
import VueElementLoading from "vue-element-loading";

export default {
  components: {
    VueElementLoading
  },
  computed: {
    appLogin() {
      return store.state.isLoggedIn;
    }
  },
  data() {
    return {
      appLoading: false,
      drawer: true,
      items: [
        {
          icon: "mdi-view-dashboard",
          text: "Dashboard",
          route: "/Dashboard",
          color: "#F44336"
        },
        {
          icon: "mdi-home-modern",
          text: "Project Category",
          route: "/Categories",
          color: "#2196F3"
        },
        {
          icon: "mdi-vector-square",
          text: "Project Settings",
          route: "/ProjectSettings",
          color: "#2196F3"
        },
        {
          icon: "mdi-cube-outline",
          text: "Project",
          route: "/Project",
          color: "#FF9800"
        },
        {
          icon: "mdi-account-check",
          text: "Customer Onboarding",
          route: "/KYC",
          color: "#FF9800"
        },
        {
          icon: "mdi-account-multiple",
          text: "Reports",
          color: "#FF9800",
          children: [
            {
              text: "User Listing",
              route: "/users",
              color: "#FF9800",
              icon: "mdi-account-multiple"
            },
            {
              text: "User Documents",
              route: "/Documents",
              color: "#FF9800",
              icon: "mdi-file-multiple"
            },
            {
              text: "All logs",
              route: "/AllLogs",
              color: "#FF9800",
              icon: "mdi-account-multiple"
            },
            {
              text: "User Navigation",
              route: "/UserActivity",
              color: "#FF9800",
              icon: "mdi-account-multiple"
            }
          ]
        },
        // {
        //   icon: "mdi-currency-usd",
        //   text: "Investors",
        //   route: "/Investors",
        //   color: "#673AB7"
        // },

        // {
        //   icon: "mdi-view-list",
        //   text: "All Logs",
        //   route: "/AllLogs",
        //   color: "#009688"
        // },
        // {
        //   icon: "mdi-tumblr-reblog",
        //   text: "User Activity",
        //   route: "/UserActivity",
        //   color: "#FFEB3B"
        // },

        {
          icon: "mdi-comment-question-outline",
          text: "Manage Client Query",
          route: "/Query",
          color: "#FF9800"
        },
        {
          icon: "mdi-map-marker",
          text: "Manage Countries",
          route: "/Regions",
          color: "#FF9800"
        },
        {
          icon: "mdi-blogger",
          text: "Manage Blog",
          route: "/Blog",
          color: "#FF9800"
        },
        {
          icon: "mdi-security",
          text: "Manage General FAQ",
          route: "/faq",
          color: "#FF9800"
        },
        {
          icon: "mdi-note-text",
          text: "Manage About Us Page",
          route: "/aboutus",
          color: "#FF9800"
        }
        // {
        //   icon: "mdi-map-marker",
        //   text: "Terget Metrics",
        //   route: "/InvestmentSecurities",
        //   color: "#FF9800"
        // }
      ],
      msg: null,
      userName: null
    };
  },
  beforeMount() {
    this.getProfile();
  },
  methods: {
    appLogout() {
      store.commit("logoutUser", true);
    },
    getProfile() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/profile",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        }
      })
        .then(response => {
          this.appLoading = false;
          this.userName = response.data.data.username;
        })
        .catch(err => {
          this.msg = err.response.data.msg;
          this.appLoading = false;
        });
    }
  }
};
</script>
<style>
.nav_item {
  margin-top: 5px;
  margin-right: 20px;
}
.AppHeading {
  color: white;
  font-family: proxima;
  font-size: 16px;
  font-weight: 800 !important;
  letter-spacing: 1px !important;
}
.AppMenu {
  color: white;
  font-family: opensans;
  font-size: 12px !important;
  font-weight: 100 !important;
  text-transform: uppercase;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00a972;
  /* border-radius: 10px; */
}
.v-list-group__items {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding-left: 27px;
}
</style>

