<template>
  <v-app class="appbg">
    <!-- <appHeader @appData="windowData" /> -->
    <!-- <appSidebar v-if="drawer && token" /> -->
    <router-view />
  </v-app>
</template>

<script>
// import appHeader from "./components/common/AppHeader";
// import appSidebar from "./components/common/AppSidebar";
export default {
  components: {
    // appHeader,
    // appSidebar
  },
  name: "App",
  data() {
    return {
      drawer: false,
      // token: true,
      token: localStorage.getItem("token")
    };
  },
  methods: {
    windowData(window_data) {
      this.drawer = window_data.drawer;
    }
  }
};
</script>

<style>
@font-face {
  font-family: proxima;
  src: url("./assets/fonts/Proxima_Nova_Regular.otf");
}
@font-face {
  font-family: proxima_Bold;
  src: url("./assets/fonts/Proxima_Nova_Bold.otf");
}
@font-face {
  font-family: opensans;
  src: url("./assets/fonts/OpenSans_SemiBold.ttf");
}
@font-face {
  font-family: opensans_Bold;
  src: url("./assets/fonts/OpenSans_Bold.ttf");
}
@font-face {
  font-family: opensans_Regular;
  src: url("./assets/fonts/OpenSans_Regular.ttf");
}
@font-face {
  font-family: montserrat;
  src: url("./assets/fonts/Montserrat_SemiBold.ttf");
}
@font-face {
  font-family: montserrat_Bold;
  src: url("./assets/fonts/Montserrat_Bold.ttf");
}
@font-face {
  font-family: montserrat_Regular;
  src: url("./assets/fonts/Montserrat_Regular.ttf");
}

.v-application .primary--text {
  color: #26af82 !important;
  caret-color: #26af82 !important;
}
.color-primary {
  color: #1fb07c !important; /*AppHeader */
}
.color-secondary {
  color: #00a972 !important; /*Home Text*/
}

</style>