import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import axios from 'axios'

// import AppHome from '@/components/common/AppHome'
import AppLogin from '@/components/Common/AppLogin'
import Dashboard from '../components/Dashboard/Dashboard'
import test from '../components/Dashboard/test'


Vue.use(Router)

let router = new Router({
	linkExactActiveClass: 'active',
	mode: 'history',
	routes: [
		{
			path: '/',
			redirect: 'Dashboard',
			component: Dashboard,
			meta: {
				requiresAuth: true
			},
			children: [
				{
					path: '/Dashboard',
					name: 'Dashboard',
					component: test
				},
				{
					path: '/Users',
					name: 'Users',
					component: () => import('../components/Users/Users')
				},
				{
					path: '/Investors',
					name: 'Investors',
					component: () => import('../components/Users/Investors')
				},
				{
					path: '/Company',
					name: 'Company',
					component: () => import('../components/Users/Company')
				},
				{
					path: '/AllLogs',
					name: 'AllLogs',
					component: () => import('../components/Logs/AppAllLogs')
				},
				{
					path: '/UserActivity',
					name: 'UserActivity',
					component: () => import('../components/Logs/AppUserActivity')
				},
				{
					path: '/ViewUser/:id',
					name: 'ViewUser',
					props: true,
					component: () => import('../components/Users/ViewUser')
				},
				{
					path: '/Query',
					name: 'Query',
					component: () => import('../components/Query/Query')
				},
				{
					path: '/ViewQuery/:id',
					name: 'ViewQuery',
					props: true,
					component: () => import('../components/Query/ViewQuery')
				},
				{
					path: '/ViewLog/:id',
					name: 'ViewLog',
					props: true,
					component: () => import('../components/Logs/ViewLog')
				},
				{
					path: '/Regions',
					name: 'Regions',
					component: () => import('../components/Region/Regions')
				},
				{
					path: '/Project',
					name: 'Project',
					component: () => import('../components/Project/Project')
				},
				{
					path: '/ProjectSettings',
					name: 'ProjectSettings',
					component: () => import('../components/Project/ProjectSettings')
				},
				{
					path: '/AddProject',
					name: 'AddProject',
					component: () => import('../components/Project/AddProject')
				},
				{
					path: '/AddProjectNew',
					name: 'AddProjectNew',
					component: () => import('../components/Project/AddProjectNew')
				},
				{
					path: '/ViewProject/:id',
					name: 'ViewProject',
					props: true,
					component: () => import('../components/Project/ViewProject')
				},
				{
					path: '/EditProject/:id',
					name: 'EditProject',
					props: true,
					component: () => import('../components/Project/EditProject')
				},
				{
					path: '/InvestmentSecurities',
					name: 'InvestmentSecurities',
					component: () => import('../components/InvestmentSecurity/InvestmentSecurities')
				},
				{
					path: '/AddRegion',
					name: 'AddRegion',
					component: () => import('../components/Region/AddRegion')
				},
				{
					path: '/ViewRegion/:id',
					name: 'ViewRegion',
					props: true,
					component: () => import('../components/Region/ViewRegion')
				},
				{
					path: '/EditRegion/:id',
					name: 'EditRegion',
					props: true,
					component: () => import('../components/Region/EditRegion')
				},
				{
					path: '/Categories',
					name: 'Categories',
					component: () => import('../components/Category/Categories')
				},
				{
					path: '/AddCategory',
					name: 'AddCategory',
					component: () => import('../components/Category/AddCategory')
				},
				{
					path: '/ViewCategory/:id',
					name: 'ViewCategory',
					props: true,
					component: () => import('../components/Category/ViewCategory')
				},
				{
					path: '/EditCategory/:id',
					name: 'EditCategory',
					props: true,
					component: () => import('../components/Category/EditCategory')
				},
				{
					path: '/faq/',
					name: 'Faq',
					props: true,
					component: () => import('../components/Faq/faq')
				},
				{
					path: '/aboutus/',
					name: 'AboutUs',
					props: true,
					component: () => import('../components/AboutUs/aboutUs')
				},
				{
					path: '/ProjectProgress/:name/:id',
					name: 'ProjectProgress',
					props: true,
					component: () => import('../components/Project/ProjectProgress')
				},
				{
					path: '/ProjectDocuments/:name/:id',
					name: 'ProjectDocuments',
					props: true,
					component: () => import('../components/Project/ProjectDocuments')
				},
				{
					path: '/Blog/',
					name: 'Blog',
					props: true,
					component: () => import('../components/Blog/Blog')
				},
				{
					path: '/ViewBlog/:id',
					name: 'ViewBlog',
					props: true,
					component: () => import('../components/Blog/viewBlog')
				},
				{
					path: '/AddBlog/',
					name: 'AddBlog',
					props: true,
					component: () => import('../components/Blog/AddBlog')
				},
				{
					path: '/EditBlog/:id',
					name: 'EditBlog',
					props: true,
					component: () => import('../components/Blog/EditBlog')
				},
				{
					path: '/ProjectInvestors/:id',
					name: 'ProjectInvestors',
					props: true,
					component: () => import('../components/Project/ProjectInvestors')
				},
				{
					path: '/ProjectPledge/:id',
					name: 'ProjectPledge',
					props: true,
					component: () => import('../components/Project/ProjectPledge')
				},
				{
					path: '/KYC/',
					name: 'KYC',
					props: true,
					component: () => import('../components/KYC/KYC')
				},
				{
					path: '/ManageKYC/:id',
					name: 'ManageKYC',
					props: true,
					component: () => import('../components/KYC/manageKYC')
				},
				{
					path: '/Documents/',
					name: 'Documents',
					props: true,
					component: () => import('../components/Documents/documents')
				},
				{
					path: '/UserDocuments/:id',
					name: 'UserDocuments',
					props: true,
					component: () => import('../components/Documents/userDocuments')
				},

			]
		},
		{
			path: '/Login',
			redirect: 'Login',
			component: AppLogin,
			children: [
				{
					path: '/Login',
					name: 'Login',
					component: AppLogin
				}
			]
		},
		{
			path: '/profile',
			name: 'profile',
			component: () => import('../components/Common/AppProfile.vue')
		},
		{
			path: '/Timeout',
			name: 'Timeout',
			component: () => import('../components/Common/AppTimeout')
		},

		{
			path: '*',
			name: 'NotFound',
			props: true,
			component: () => import('../components/Common/404'),
			meta: {
				description: 'App 404 Page',
				requiresAuth: false
			}
		},
		{
			path: '/ServerError',
			name: 'ServerError',
			props: true,
			component: () => import('../components/Common/500'),
			meta: {
				description: 'App Server Error Page',
				requiresAuth: false
			}
		},
	]
})


router.beforeEach((to, from, next) => {
	axios({
		method: 'POST',
		url: '/log/navigate',
		headers: {
			'x-auth-token': localStorage.getItem('token')
		},
		params: {
			pagename: to.name,
			// description: to.meta.description
		}
	})
	if (to.matched.some(route => route.meta.requiresAuth && store.state.isLoggedIn)) {
		axios({
			method: 'POST',
			url: '/validate/token',
			headers: {
				'x-auth-token': localStorage.getItem('token')
			}
		}).then(response => {
			if (!response.data.status) {
				store.commit("logoutUser", true);
				next({
					path: '/Timeout',
				})
				return
			}
		})
	}
	if (to.matched.some(route => route.meta.requiresAuth) && !store.state.isLoggedIn) {
		next({
			path: '/Login',
		})
		return
	}
	if (to.path == '/Login' && store.state.isLoggedIn) {
		next({
			path: '/Dashboard'
		})
		return
	}
	next()
})

export default router