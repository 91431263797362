<template>
  <div id="app_signup">
    <v-layout wrap>
      <v-flex xs12 md12 pa-8>
        <v-card id="app_height" outlined tile>
          <loginMain v-if="winStep == 0" @stepper="winStepper" />
          <loginVerify v-bind:loginData="loginData" v-if="winStep == 1" @stepper="winStepper" />
          <loginSuccess v-bind:loginData="loginData" v-if="winStep == 2" />
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import loginMain from "../Login/loginMain";
import loginVerify from "../Login/loginVerify";
import loginSuccess from "../Login/loginSuccess";

export default {
  components: {
    loginMain,
    loginVerify,
    loginSuccess
  },
  data() {
    return {
      winStep: 0,
      userName: null,
      password: null,
      loginData: {},
      otperr: false,
      msg:null
    };
  },
  methods: {
    winStepper(window_data) {
      this.winStep = window_data.winStep;
      this.loginData.authcode = window_data.authcode;
      this.loginData.userId = window_data.userId;
      this.loginData.mailId = window_data.mailId;
      this.loginData.progress = window_data.progress;
      // this.otperr = window_data.otperr;
      // if (this.otperr) {
      //   this.winStep = 1;
      //   this.msg = "Incorrect one-time PIN";
      // }
    }
  }
};
</script>

<style scoped>
#app_signup {
  background-image: url("../../assets/home_header.png");
  /* background-color: #26af82; */
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}
@media only screen and (min-width: 800px) {
  #app_height {
    min-height: 90vh;
  }
}
</style>
