import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from './router'

Vue.use(Vuex)
export default new Vuex.Store({
	state: {
		isLoggedIn: !!localStorage.getItem('isLoggedIn'),
		useremail: !!localStorage.getItem('email'),
		token: !!localStorage.getItem('token'),
		userType: !!localStorage.getItem('userType'),
		isIndividual: !!localStorage.getItem('individual'),
		isCompany: !!localStorage.getItem('company'),
		completeProfile: !!localStorage.getItem('completeProfile')
	},
	mutations: {
		loginUser(state) {
			state.isLoggedIn = true
			localStorage.setItem('isLoggedIn', true)
		},
		individual(state) {
			state.isIndividual = true
			state.userType = 'Individual'
			localStorage.setItem("userType", "Individual");
			localStorage.setItem("individual", true);
		},
		company(state) {
			state.isCompany = true
			state.userType = 'Company'
			localStorage.setItem("userType", "Company");
			localStorage.setItem("company", true);
		},
		completedUser(state) {
			state.completeProfile = true
			localStorage.setItem('completeProfile', true)
		},
		logoutUser(state) {
			axios({
				method: "GET",
				url: "/user/logout",
				headers: {
					"x-auth-token": localStorage.getItem("token")
				}
			})
			state.isLoggedIn = false
			localStorage.removeItem('isLoggedIn')
			localStorage.removeItem("token");
			localStorage.removeItem("userType");
			localStorage.removeItem("company");
			localStorage.removeItem("individual");
			localStorage.removeItem("userId");
			localStorage.removeItem("email");
			router.push('/Login')
		}

	}

})