<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <div v-if="dashboard" style="min-width:80vw">
      <!-- Users -->
      <v-layout px-4 wrap justify-center>
        <v-flex xs12 sm4 md3 pa-4>
          <v-card>
            <v-layout wrap pa-2 style="background-color:#f9f9f9;">
              <v-flex xs12 sm12 md12 text-left>
                <span style="font-size:16px;font-weight:400;color:#000">Total users</span>
              </v-flex>
              <v-flex xs12 sm12 md12 text-center>
                <span style="font-size:18px;font-weight:800;color:#000">{{dashboard.user.total}}</span>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <v-layout wrap pa-2 style="background-color:#00a972"></v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 sm4 md3 pa-4>
          <v-card>
            <v-layout wrap pa-2 style="background-color:#f9f9f9;">
              <v-flex xs12 sm12 md12 text-left>
                <span style="font-size:16px;font-weight:400;color:#000">Total Inividual Users</span>
              </v-flex>
              <v-flex xs12 sm12 md12 text-center>
                <span style="font-size:18px;font-weight:800;color:#000">{{dashboard.user.inividual}}</span>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <v-layout wrap pa-2 style="background-color:#00a972"></v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 sm4 md3 pa-4>
          <v-card>
            <v-layout wrap pa-2 style="background-color:#f9f9f9;">
              <v-flex xs12 sm12 md12 text-left>
                <span style="font-size:16px;font-weight:400;color:#000">Total Company Users</span>
              </v-flex>
              <v-flex xs12 sm12 md12 text-center>
                <span style="font-size:18px;font-weight:800;color:#000">{{dashboard.user.company}}</span>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <v-layout wrap pa-2 style="background-color:#00a972"></v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 sm4 md3 pa-4>
          <v-card>
            <v-layout wrap pa-2 style="background-color:#f9f9f9;">
              <v-flex xs12 sm12 md12 text-left>
                <span style="font-size:16px;font-weight:400;color:#000">Total Admins</span>
              </v-flex>
              <v-flex xs12 sm12 md12 text-center>
                <span style="font-size:18px;font-weight:800;color:#000">{{dashboard.user.admin}}</span>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <v-layout wrap pa-2 style="background-color:#00a972"></v-layout>
          </v-card>
        </v-flex>
      </v-layout>
      <!-- Investment -->
      <v-divider></v-divider>
      <v-layout px-4 wrap justify-center>
        <v-flex xs12 sm4 md3 pa-4>
          <v-card>
            <v-layout wrap pa-2 style="background-color:#f9f9f9;">
              <v-flex xs12 sm12 md12 text-left>
                <span style="font-size:16px;font-weight:400;color:#000">Total Investors</span>
              </v-flex>
              <v-flex xs12 sm12 md12 text-center>
                <span
                  style="font-size:18px;font-weight:800;color:#000"
                >{{dashboard.investors.totalcount}}</span>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <v-layout wrap pa-2 style="background-color:#00a972"></v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 sm4 md3 pa-4>
          <v-card>
            <v-layout wrap pa-2 style="background-color:#f9f9f9;">
              <v-flex xs12 sm12 md12 text-left>
                <span style="font-size:16px;font-weight:400;color:#000">Total Invested Amount</span>
              </v-flex>
              <v-flex xs12 sm12 md12 text-center>
                <span
                  style="font-size:18px;font-weight:800;color:#000"
                >{{dashboard.investors.investmentamount}}</span>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <v-layout wrap pa-2 style="background-color:#00a972"></v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 sm4 md3 pa-4>
          <v-card>
            <v-layout wrap pa-2 style="background-color:#f9f9f9;">
              <v-flex xs12 sm12 md12 text-left>
                <span style="font-size:16px;font-weight:400;color:#000">Total Individual Investors</span>
              </v-flex>
              <v-flex xs12 sm12 md12 text-center>
                <span
                  style="font-size:18px;font-weight:800;color:#000"
                >{{dashboard.investors.inividualcount}}</span>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <v-layout wrap pa-2 style="background-color:#00a972"></v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 sm4 md3 pa-4>
          <v-card>
            <v-layout wrap pa-2 style="background-color:#f9f9f9;">
              <v-flex xs12 sm12 md12 text-left>
                <span style="font-size:16px;font-weight:400;color:#000">Total Company Investors</span>
              </v-flex>
              <v-flex xs12 sm12 md12 text-center>
                <span
                  style="font-size:18px;font-weight:800;color:#000"
                >{{dashboard.investors.companycount}}</span>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <v-layout wrap pa-2 style="background-color:#00a972"></v-layout>
          </v-card>
        </v-flex>
      </v-layout>
      <!-- Project -->
      <v-divider></v-divider>
      <v-layout px-4 wrap justify-center>
        <v-flex xs12 sm4 md4 pa-4>
          <v-card>
            <v-layout wrap pa-2 style="background-color:#f9f9f9;">
              <v-flex xs12 sm12 md12 text-left>
                <span style="font-size:16px;font-weight:400;color:#000">Total Projects</span>
              </v-flex>
              <v-flex xs12 sm12 md12 text-center>
                <span
                  style="font-size:18px;font-weight:800;color:#000"
                >{{dashboard.project.totalamount}}</span>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <v-layout wrap pa-2 style="background-color:#00a972"></v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 sm4 md4 pa-4>
          <v-card>
            <v-layout wrap pa-2 style="background-color:#f9f9f9;">
              <v-flex xs12 sm12 md12 text-left>
                <span style="font-size:16px;font-weight:400;color:#000">Total Published Projects</span>
              </v-flex>
              <v-flex xs12 sm12 md12 text-center>
                <span
                  style="font-size:18px;font-weight:800;color:#000"
                >{{dashboard.project.totalpublished}}</span>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <v-layout wrap pa-2 style="background-color:#00a972"></v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 sm4 md4 pa-4>
          <v-card>
            <v-layout wrap pa-2 style="background-color:#f9f9f9;">
              <v-flex xs12 sm12 md12 text-left>
                <span style="font-size:16px;font-weight:400;color:#000">Total Unpublished Projects</span>
              </v-flex>
              <v-flex xs12 sm12 md12 text-center>
                <span
                  style="font-size:18px;font-weight:800;color:#000"
                >{{dashboard.project.totalunpublished}}</span>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <v-layout wrap pa-2 style="background-color:#00a972"></v-layout>
          </v-card>
        </v-flex>
      </v-layout>
      <!-- Query -->
      <v-divider></v-divider>
      <v-layout px-4 wrap justify-center>
        <v-flex xs12 sm4 md4 pa-4>
          <v-card>
            <v-layout wrap pa-2 style="background-color:#f9f9f9;">
              <v-flex xs12 sm12 md12 text-left>
                <span style="font-size:16px;font-weight:400;color:#000">Total Queries</span>
              </v-flex>
              <v-flex xs12 sm12 md12 text-center>
                <span style="font-size:18px;font-weight:800;color:#000">{{dashboard.query.total}}</span>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <v-layout wrap pa-2 style="background-color:#00a972"></v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 sm4 md4 pa-4>
          <v-card>
            <v-layout wrap pa-2 style="background-color:#f9f9f9;">
              <v-flex xs12 sm12 md12 text-left>
                <span style="font-size:16px;font-weight:400;color:#000">Total Queries Unread</span>
              </v-flex>
              <v-flex xs12 sm12 md12 text-center>
                <span style="font-size:18px;font-weight:800;color:#000">{{dashboard.query.unread}}</span>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <v-layout wrap pa-2 style="background-color:#00a972"></v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 sm4 md4 pa-4>
          <v-card>
            <v-layout wrap pa-2 style="background-color:#f9f9f9;">
              <v-flex xs12 sm12 md12 text-left>
                <span style="font-size:16px;font-weight:400;color:#000">Total Queries Read</span>
              </v-flex>
              <v-flex xs12 sm12 md12 text-center>
                <span style="font-size:18px;font-weight:800;color:#000">{{dashboard.query.read}}</span>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <v-layout wrap pa-2 style="background-color:#00a972"></v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 3000,
      msg: null,
      dashboard: null
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/dashboard/admin",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        }
      })
        .then(response => {
          this.appLoading = false;
          console.log(response);
          this.dashboard = response.data.data;
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    }
  }
};
</script>